import { MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import './App.css';
import Router from './Router';
import theme from './styles/theme';

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router />
    </MuiThemeProvider>
  );
}

export default App;
