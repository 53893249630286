/** @jsx jsx */
import { jsx } from '@emotion/core';
import { AppBar, Toolbar } from '@material-ui/core'
import { FC } from 'react';

type Props = {
  title: string
}

export const Appbar: FC<Props> = ({ title }) => {
  return (
    <AppBar position="static" color='primary'>
      <Toolbar>
        <h1 style={{
          fontSize: '20px'
        }}>
          {title}
        </h1>
      </Toolbar>
    </AppBar>
  );
}