/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Button } from '@material-ui/core';
import queryString, { ParsedQuery } from 'query-string';
import { FC, useEffect } from 'react';
import { ChannelService } from '../../../services/channel_talk';
import { Appbar } from '../../atoms/Appbar';

export const Boot: FC = () => {
  useEffect(() => {
    const qs: ParsedQuery = queryString.parse(window.location.search);
    const ikasaId = qs['ikasa_id'];

    ChannelService.boot({
      "pluginKey": process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY,
      "memberId": ikasaId,
      "hideDefaultLauncher": true,
      "mobileOpenUI": "newTab",
      "zIndex": 1,
      "locale": "ja",
      "language": "ja",
      // "unsubscribed": false,
      // "customLauncherSelector": ".custom-button-1, #custom-button-2",
      // "enabledTrackDefaultEvent": false,
      // "enabledUtmSourceTrack": false,
      // "openChatDirectlyAsPossible": true,
      // "profile": {
      //   "name": "YOUR_USER_NAME",
      //   "mobileNumber": "YOUR_USER_MOBILE_NUMBER",
      //   "email": "your@email.com",
      //   "avatarUrl": "http://IMAGE_URL",
      //   "CUSTOM_VALUE_1": "VALUE_1",
      //   "CUSTOM_VALUE_2": "VALUE_2"
      // },
      // "memberHash": <generted_hash>
    });

    // ChannelService.startChatEvent(ikasaId)

    ChannelService.show(ikasaId);
  }, [])


  return (
    <div>
      <Appbar title='お問い合わせ' />
      <Box
        alignContent='center'
        margin='150px 20px'
        textAlign='center'
      >
        <p>チャット画面が自動開かない場合は、<br />以下のボタンを押してください</p>

        <Button
          css={{ padding: '10px 100px', margin: '30px auto', fontWeight: 'bold' }}
          variant="contained"
          size="large"
          color="primary"
          style={{
            borderRadius: '24px'
          }}
          onClick={ChannelService.show}
        >
          チャット
        </Button>
      </Box>
    </div>
  )
}