/**  @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter,
} from 'react-router-dom'
import { Boot } from './view/pages/Boot'

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect from="/index.html" to="/" />
        <Route exact={true} path="/boot" component={Boot} />
        <Route component={Boot} />
      </Switch>
    </BrowserRouter>
  )
}
